<template>
  <div class="cards">
    <div class="card one">1</div>
    <div class="card two">2</div>
    <div class="card three">3</div>
    <div class="card five">5</div>
    <div class="card eight">8</div>
    <p>Loading...</p>
  </div>
</template>

<script>
export default {
  name: "SSLoading"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cards {
  position: relative;
  margin: 50px auto 100px;
  height: 75px;
  width: 50px;
  font-size: 30px;
  line-height: 34px;
  font-weight: bold;

  .card {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    border: 1px solid black;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
  p {
    font-size: 0.5em;
    position: absolute;
    bottom: -45px;
    left: -13px;
    margin: 0;
  }
  .one {
    animation-name: anim-one;
    @keyframes anim-one {
      from {
        transform: translate(0) rotate(0deg);
      }
      50% {
        transform: translate(-30px, 5px) rotate(-45deg);
      }
      to {
        transform: translate(0) rotate(0deg);
      }
    }
  }
  .two {
    animation-name: anim-two;
    @keyframes anim-two {
      from {
        transform: translate(0) rotate(0deg);
      }
      50% {
        transform: translate(-15px) rotate(-25deg);
      }
      to {
        transform: translate(0) rotate(0deg);
      }
    }
  }
  .three {
    animation-name: anim-three;
    @keyframes anim-three {
      from {
        transform: translate(0) rotate(0deg);
      }
      50% {
        transform: rotate(-5deg);
      }
      to {
        transform: translate(0) rotate(0deg);
      }
    }
  }
  .five {
    animation-name: anim-five;
    @keyframes anim-five {
      from {
        transform: translate(0) rotate(0deg);
      }
      50% {
        transform: translate(15px, 5px) rotate(15deg);
      }
      to {
        transform: translate(0) rotate(0deg);
      }
    }
  }
  .eight {
    animation-name: anim-eight;
    @keyframes anim-eight {
      from {
        transform: translate(0) rotate(0deg);
      }
      50% {
        transform: translate(28px, 14px) rotate(35deg);
      }
      to {
        transform: translate(0) rotate(0deg);
      }
    }
  }
}
</style>
