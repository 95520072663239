import { defineStore } from "pinia";

export const usePostStore = defineStore("post", {
  state: () => ({
    posts: [],
    total: 0
  }),
  getters: {
    postsLength: state => state.posts.length,
    hasPosts: state => state.posts.length > 0,
    latestPost: state => state.posts[0],
    hasMorePosts: state => state.posts.length < state.total
  },
  actions: {
    addPosts(posts) {
      this.posts.push(...posts);
    },
    setTotal(total) {
      this.total = total;
    }
  }
});
