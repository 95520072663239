<template>
  <header class="ssheader container m-auto">
    <div class="flex items-center">
      <router-link to="/" class="block w-3/4 lg:w-auto">
        <img
          class="main-logo"
          alt="Straight Scrum"
          src="@/assets/images/full-logo.svg"
        />
      </router-link>
      <div class="flex justify-center lg:hidden items-center w-1/4">
        <button
          @click="onHamburgerClick"
          class="flex items-center px-3 py-2 border rounded text-secondary border-secondary hover:text-primary hover:border-primary"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <nav
        class="hidden lg:flex text-center justify-center mainNav items-center w-full justify-center"
      >
        <router-link to="/about">About</router-link>
        <router-link to="/blog">Blog</router-link>
        <!-- <router-link to="/courses">Courses</router-link> -->
        <router-link to="/resources">Resources</router-link>
        <router-link to="/contact">Contact</router-link>
      </nav>
    </div>
    <nav
      class="mobileNav items-center lg:hidden border-b border-gray"
      :class="{ navExpanded: isExpanded }"
    >
      <router-link to="/about">About</router-link>
      <router-link to="/blog">Blog</router-link>
      <!-- <router-link to="/courses">Courses</router-link> -->
      <router-link to="/resources">Resources</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: "SSHeader",
  data: () => ({
    isExpanded: false
  }),
  methods: {
    onHamburgerClick: function() {
      this.isExpanded = !this.isExpanded;
    }
  },
  watch: {
    $route() {
      this.isExpanded = false;
    }
  }
};
</script>

<style scoped lang="scss">
.ssheader {
  .main-logo {
    width: 100%;
    max-width: 400px;
    @media only screen and (min-width: 1025px) {
      width: 400px;
    }
  }
  .mainNav {
    a {
      @apply inline-block p-1 pr-2 mr-1 border-r border-secondary text-lg font-bold text-secondary;

      &:last-child {
        @apply border-none;
      }

      &:hover,
      &.router-link-active {
        color: theme("colors.primary");
      }
    }
  }
  .mobileNav {
    display: none;

    a {
      @apply block text-secondary p-3 border-t border-gray;

      &:hover,
      &:active {
        @apply bg-primary text-white;
      }
    }

    &.navExpanded {
      display: block;
      @media only screen and (min-width: 1025px) {
        display: none;
      }
    }
  }
}
</style>
