<template>
  <div class="home">
    <div class="container bg-secondary m-auto splash">
      <img
        class="splash-logo m-auto"
        alt="Let's try to get is straight before we change it"
        src="@/assets/images/icon.svg"
      />
    </div>

    <div
      class="home-content container m-auto flex flex-col lg:flex-row border-secondary border-l-4 border-r-4 p-6"
    >
      <div
        class="cell2 lg:order-2 lg:w-1/3 pb-6 mb-6 border-gray border-b lg:border-b-0 lg:border-r"
        aria-hidden="true"
      >
        <p
          class="text-primary text-center font-bold text-2xl mt-1 lg:text-3xl xl:text-4xl lg:p-10"
        >
          Let's try to get it straight <em>before</em> we try to change it
        </p>
      </div>

      <div
        class="cell1 text-lg lg:order-1 lg:w-1/3 pb-6 mb-6 border-gray border-b lg:border-b-0 lg:border-r lg:p-6"
      >
        <h2>What we can do for you</h2>
        <ul class="list-disc m-2">
          <li class="text-lg mb-2">
            Learn how to implement Scrum for your business
          </li>
          <li class="text-lg mb-2">Build your Scrum Product Owner skill set</li>
          <li class="text-lg mb-2">
            Get a mentor to guide you through your Scrum Product Owner career
          </li>
        </ul>
      </div>

      <div class="cell3 lg:order-3 lg:w-1/3 lg:p-6">
        <SSLatestBlogPost />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SSLatestBlogPost from "@/components/SSLatestBlogPost.vue";

export default {
  name: "Home",
  components: {
    SSLatestBlogPost
  }
};
</script>

<style scoped lang="scss">
.splash {
  height: 250px;
  position: relative;
}
.splash-logo {
  width: 300px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  margin-left: -150px;
}

.home-content {
  h2 {
    @apply font-bold;
  }
}
</style>
