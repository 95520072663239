// Page utilities

export const toCssClassName = inputString => {
  if (!inputString) {
    return;
  }
  return inputString
    .toLowerCase()
    .replace(/[\s]/g, "-")
    .replace(/[^a-z-]/g, "");
};

export const blogLink = post => {
  if (post && post.fields && post.fields.slug) {
    return `/blog/${post.fields.slug}`;
  }
  return "/blog";
};

export const prettyDate = dateString => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("default", options);
};
