<template>
  <div>
    <template v-if="latestPost && !showLoading && !showError">
      <p class="text-sm"><em>Latest blog post</em></p>
      <h2 class="text-lg mb-2">
        {{ latestPost.fields.title }}
      </h2>
      <p>
        {{ latestPost.fields.summary }}
      </p>
      <p class="text-right">
        <router-link :to="postLink">Read More</router-link>
      </p>
    </template>
    <template v-if="showLoading && !showError">
      <SSLoading />
    </template>
    <template v-if="showError">
      <p class="text-alert font-bold">Unable to load latest blog post.</p>
    </template>
  </div>
</template>

<script>
import { mapStores } from "pinia";

import { contentful } from "@/utilities/contentful.js";
import { usePostStore } from "@/stores/posts";

import { blogLink } from "@/utilities/pageUtils.js";
import SSLoading from "@/components/SSLoading.vue";

export default {
  name: "SSLatestBlogPost",
  components: {
    SSLoading
  },
  data() {
    return {
      showLoading: false,
      showError: false
    };
  },
  computed: {
    ...mapStores(usePostStore),
    latestPost() {
      return this.postStore.latestPost;
    },
    postLink() {
      return blogLink(this.latestPost);
    }
  },
  methods: {
    initializePost() {
      if (!this.latestPost) {
        this.getPost();
      }
    },
    getPost() {
      contentful
        .getEntries({
          content_type: "post",
          limit: 1,
          // minus is reverse order
          order: "-fields.date"
        })
        .then(result => {
          this.postStore.addPosts(result.items);
          this.postStore.setTotal(result.total);
          this.showLoading = false;
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  created() {
    this.initializePost();
  }
};
</script>

<style scoped lang="scss">
h2 {
  @apply font-bold;
}
</style>
