import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue")
  },
  {
    path: "/blog/:slug",
    name: "blog post",
    component: () =>
      import(/* webpackChunkName: "blogPost" */ "../views/BlogPost.vue")
  },
  // {
  //   path: "/courses",
  //   name: "courses",
  //   component: () =>
  //     import(/* webpackChunkName: "courses" */ "../views/Courses.vue")
  // },
  {
    path: "/resources",
    name: "resources",
    component: () =>
      import(/* webpackChunkName: "resources" */ "../views/Resources.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/Success.vue")
  },
  {
    path: "/failure",
    name: "failure",
    component: () =>
      import(/* webpackChunkName: "failure" */ "../views/Failure.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
