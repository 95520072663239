<template>
  <div id="app">
    <SSHeader />
    <router-view />
    <SSFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import SSHeader from "@/components/SSHeader.vue";
import SSFooter from "@/components/SSFooter.vue";

export default {
  name: "Home",
  components: {
    SSHeader,
    SSFooter
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  p {
    a {
      @apply text-secondary underline;

      &:hover {
        @apply text-primary;
      }
    }
  }
}
</style>
