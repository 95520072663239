import { createClient } from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

export const contentful = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN
});

export function markdownToHtml(field) {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields }
        }
      }) =>
        `<p><img src="${fields.file.url}" class="h-auto max-w-lg mx-auto" alt="${fields.description}"/></p>`
    }
  };
  return documentToHtmlString(field, options);
}
