<template>
  <footer
    class="container bg-secondary m-auto text-white text-center text-xs p-2"
  >
    All rights reserved. &copy;{{ currentYear }} Straight Scrum LLC.
  </footer>
</template>

<script>
export default {
  name: "SSFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
